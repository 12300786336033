import axios from 'axios'
import settings from 'settings.json'

const serverURL = settings[process.env.REACT_APP_DEPLOY_ENV].server_url

export const fetchUserFlow = async (
  segmentId: string,
  signal?: AbortSignal
) => {
  const response = await axios.get(
    `${serverURL}/api/segment/${segmentId}/userflow`,
    {
      signal,
    }
  )
  const data = response.data

  return data
}
