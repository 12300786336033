/***
 *
 *   CHART BUILDER
 *
 **********/

import React, { useContext, useState, useEffect, useRef } from 'react'
import { toast } from 'react-toastify'

import { logger } from 'utils/logger'
import dappSymbol from 'assets/images/dapp-symbol.svg'
import { DashboardContentContext } from 'app/contexts/DashboardContentContext'
import { Typography, Loader } from 'components/lib'
import { TResult, ResultSchema } from 'types/result'
import { fetchResultData } from 'utils/fetches/resultData'
import {
  removeUselessProperties,
  getValidationErrorMessage,
} from 'utils/helpers'

import { DeleteSegment } from 'features/builders/shared/components/DeleteSegment'
import { SegmentsManagement } from 'features/builders/segments/SegmentsManagement'
import { SegmentManagementModalContext } from 'features/builders/shared/contexts/SegmentManagementModalContext'
import { DeleteSegmentModalContext } from 'features/builders/shared/contexts/DeleteSegmentModalContext'
import { BlockChartContext } from 'features/builders/shared/contexts/BlockChartContext'
import { ResultView } from 'features/builders/chartBuilder/components/ResultView'
import { ChartBuilderSidebar } from 'features/builders/chartBuilder/components/ChartBuilderSidebar'
import { SettingsChart } from 'features/builders/chartBuilder/components/SettingsChart'
import { Window } from 'features/builders/shared/components/Window'
import { AddSegment } from 'features/builders/shared/components/AddSegment'
import { AddSegmentModalContext } from 'features/builders/shared/contexts/AddSegmentModalContext'
import Style from './ChartBuilder.module.css'

type TSelectedUnits = {
  name: string
  icon?: string
  label: string
  value: string
}

type TSelectedSegments = {
  name: string
  icon?: string
  label: string
  value: string
}

type TSelectedB = {
  name: string
  icon: string
}

export function ChartBuilder() {
  const [result, setResult] = useState<TResult | undefined>()
  const [isLoadingResult, setIsLoadingResult] = useState<boolean | undefined>()
  const [chartType, setChartType] = useState<string>('lineChart')
  const [chartTitle, setChartTitle] = useState<string>('Default Chart Name')
  const [selectedUnit, setSelectedUnit] = useState<TSelectedUnits | undefined>()
  const [selectedSegment, setSelectedSegment] = useState<
    TSelectedSegments | undefined
  >()
  const [selectedEvents, setSelectedEvents] = useState<TSelectedB | undefined>()
  const [selectedCalls, setSelectedCalls] = useState<TSelectedB | undefined>()
  const [isScBreakdown, setIsScBreakdown] = useState<boolean>(false)
  const blockChartContext = useContext(BlockChartContext)
  const dashboardContentContext = useContext(DashboardContentContext)
  const addSegmentContext = useContext(AddSegmentModalContext)
  const deleteSegmentContext = useContext(DeleteSegmentModalContext)
  const segmentManagmentContext = useContext(SegmentManagementModalContext)
  const dataResultController = useRef<AbortController | null>(null)

  if (!deleteSegmentContext) {
    throw Error('Delete Segment modal context has to be in provider')
  }

  if (!segmentManagmentContext) {
    throw Error('Segment Managment context has to be in provider')
  }

  if (!addSegmentContext) {
    throw Error('Segment modal context has to be in provider')
  }

  if (!blockChartContext) {
    throw Error('Chart context has to be in provider')
  }

  if (!dashboardContentContext) {
    throw Error('Dashboard context has to be in provider')
  }

  const { isDisplayingSegmentManagementModal } = segmentManagmentContext
  const { segmentToDelete, setSegmentToDelete } = deleteSegmentContext
  const { isDisplayingAddSegmentModal } = addSegmentContext
  const { blockChartId, setBlockChartId } = blockChartContext
  const { dashboardElements, dashboardDappId } = dashboardContentContext

  useEffect(() => {
    const editedChartElement = dashboardElements.filter(
      (item) => item.id === blockChartId
    )[0]
    if (editedChartElement) {
      setIsScBreakdown(editedChartElement.breakdown)
      setSelectedUnit({
        value: editedChartElement.metric,
        name: editedChartElement.metric,
        label: editedChartElement.metric,
        icon: editedChartElement.metric,
      })
      setChartTitle(editedChartElement.title)
      setChartType(editedChartElement.visType)
      const calls = editedChartElement.filters.filter(
        (item: any) => item.type === 'call'
      )
      setSelectedCalls(calls)
      const events = editedChartElement.filters.filter(
        (item: any) => item.type === 'event'
      )
      setSelectedEvents(events)
      if (editedChartElement?.segmentId) {
        setSelectedSegment({ id: editedChartElement.segmentId })
      }
    }
  }, [blockChartId])

  useEffect(() => {
    if (selectedUnit) {
      const fetchData = async () => {
        try {
          if (dataResultController.current) {
            dataResultController.current.abort()
          }

          dataResultController.current = new AbortController()
          const signal = dataResultController.current.signal
          setIsLoadingResult(true)
          const selectedCallsWithoutFilterOptions =
            removeUselessProperties(selectedCalls)
          const selectedEventsWithoutFilterOptions =
            removeUselessProperties(selectedEvents)
          const bodyRequest = {
            breakdown: isScBreakdown,
            filters: [],
          }
          if (selectedSegment) {
            bodyRequest.segmentId = selectedSegment.id
          }
          selectedCallsWithoutFilterOptions &&
            bodyRequest.filters.push(...selectedCallsWithoutFilterOptions)
          selectedEventsWithoutFilterOptions &&
            bodyRequest.filters.push(...selectedEventsWithoutFilterOptions)
          const fetchedResultData = await fetchResultData(
            dashboardDappId,
            selectedUnit.value,
            bodyRequest,
            signal
          )
          const validatedResultData = ResultSchema.safeParse(
            fetchedResultData.output
          )
          if (!validatedResultData.success) {
            throw new Error(validatedResultData.error.message)
          }
          setIsLoadingResult(false)
          setResult(validatedResultData.data)
        } catch (err) {
          if (err.code !== 'ERR_CANCELED') {
            setIsLoadingResult(false)
            logger.error(err.message)
            toast.error(getValidationErrorMessage('result'))
          }
        }
      }

      fetchData()
    } else {
      setResult(undefined)
      setIsScBreakdown(false)
    }
  }, [
    selectedUnit?.value,
    isScBreakdown,
    selectedCalls,
    selectedEvents,
    selectedSegment,
  ])

  return (
    <>
      {isDisplayingAddSegmentModal && <AddSegment dappId={dashboardDappId} />}
      {isDisplayingSegmentManagementModal && <SegmentsManagement />}
      {segmentToDelete && (
        <DeleteSegment
          segment={segmentToDelete}
          setSegmentToDelete={setSegmentToDelete}
        />
      )}
      <Window>
        <div className={Style['builder-container']}>
          <ChartBuilderSidebar
            setBlockChartId={setBlockChartId}
            selectedUnit={selectedUnit}
            setSelectedUnit={setSelectedUnit}
            selectedEvents={selectedEvents}
            setSelectedEvents={setSelectedEvents}
            selectedCalls={selectedCalls}
            setSelectedCalls={setSelectedCalls}
            selectedSegment={selectedSegment}
            setSelectedSegment={setSelectedSegment}
            isScBreakdown={isScBreakdown}
            setIsScBreakdown={setIsScBreakdown}
            dappId={dashboardDappId}
            isDisplayingAddSegmentModal={isDisplayingAddSegmentModal}
            segmentToDelete={segmentToDelete}
          />
          <div className={Style['preview']}>
            {!result && !isLoadingResult && (
              <div className={Style['prevew-content-container']}>
                <img
                  src={dappSymbol}
                  alt="dapp symbol"
                  className={Style['img-distance']}
                />
                <Typography
                  text="Select a unit to get started"
                  size="m"
                  weight="semi-bold"
                  color="gray900"
                  tag="p"
                />
              </div>
            )}
            {result && !isLoadingResult && (
              <>
                <SettingsChart
                  setChartType={setChartType}
                  chartType={chartType}
                  setChartTitle={setChartTitle}
                  chartTitle={chartTitle}
                  dappId={dashboardDappId}
                  selectedUnit={selectedUnit}
                  isScBreakdown={isScBreakdown}
                  result={result}
                  selectedCalls={selectedCalls}
                  selectedEvents={selectedEvents}
                  selectedSegment={selectedSegment}
                />
                <ResultView
                  data={result}
                  selectedUnit={selectedUnit}
                  chartType={chartType}
                  chartTitle={chartTitle}
                />
              </>
            )}
            {isLoadingResult && <Loader />}
          </div>
        </div>
      </Window>
    </>
  )
}
