/***
 *
 *  SEGMENT  MANAGMENT MODAL CONTEXT
 *
 **********/

import React, { useState, createContext, ReactNode } from 'react'

export interface ISegmentManagementModalContextProps {
  isDisplayingSegmentManagementModal: boolean
  setIsDisplayingSegmentManagementModal: React.Dispatch<
    React.SetStateAction<boolean>
  >
}

interface ISegmentManagementModalProviderProps {
  children: ReactNode
}

export const SegmentManagementModalContext = createContext<
  ISegmentManagementModalContextProps | undefined
>(undefined)

export function SegmentManagementModalProvider({
  children,
}: ISegmentManagementModalProviderProps) {
  const [
    isDisplayingSegmentManagementModal,
    setIsDisplayingSegmentManagementModal,
  ] = useState(false)

  return (
    <SegmentManagementModalContext.Provider
      value={{
        isDisplayingSegmentManagementModal,
        setIsDisplayingSegmentManagementModal,
      }}
    >
      {children}
    </SegmentManagementModalContext.Provider>
  )
}
