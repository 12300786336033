import React from 'react'

import { Typography, Line, DropdownNew, IconButton, Icon } from 'components/lib'

import { MetricCard } from 'features/builders/shared/components/MetricCard'
import { EmptyMetricCard } from 'features/builders/shared/components/EmptyMetricCard'
import { MetricBar } from 'features/builders/shared/components/MetricBar'
import { SegmentDropdownAdditionalContent } from 'features/builders/shared/components/SegmentDropdownAdditionalContent'
import Style from './FlowBuilderSidebar.module.css'

export const FlowBuilderSidebar = ({
  setBlockFlowId,
  setSelectedSegment,
  selectedSegment,
  segmentsOptions,
}) => {

  const handleSegment = (item) => {
    setSelectedSegment((prevSelectedSegment) => {
      if (prevSelectedSegment?.id === item.id) {
        return undefined
      } else {
        return item
      }
    })
  }

  return (
    <div className={Style['list']}>
      <div className={Style['info-container']}>
        <div className={Style['title-bar']}>
          <IconButton
            onClick={() => setBlockFlowId()}
            icon={<Icon name="back" width="16" height="16" color="gray800" />}
          />
          <Typography
            text="Set Up"
            tag="h3"
            size="m"
            weight="semi-bold"
            color="gray900"
          />
        </div>
        <Typography
          text="Choose segments to analyze"
          tag="p"
          size="s"
          weight="regular"
          color="gray700"
        />
      </div>
      <Line className="mt16 mb4" />
      {segmentsOptions ? (
        <MetricBar
          metricTitle="Segment"
          dropdownComponent={
            <DropdownNew
              title="Segment"
              size="medium"
              options={segmentsOptions.map((item) => {
                  return {
                    type:
                      item.id === selectedSegment?.id ? 'selected' : undefined,
                    label: item.name,
                    value: item.name,
                    action: () => handleSegment(item),
                  }
                })}
              position="bottom-right"
              content={<SegmentDropdownAdditionalContent />}
            >
              <IconButton
                icon={
                  <Icon name="plus" width="16" height="16" color="gray800" />
                }
              />
            </DropdownNew>
          }
        />
      ) : null}
      {selectedSegment ? (
        <MetricCard
          metricType="Segment"
          selectedSegment={selectedSegment}
          setSelectedSegment={setSelectedSegment}
        />
      ) : (
        <EmptyMetricCard metricType="Segment" metricIcon="c" />
      )}
    </div>
  )
}
