import React from 'react'

import { IconButton, Icon, Typography, useMobile } from 'components/lib'
import { TSingleSegmentDetails } from 'types/segment'

import Style from './TableOfSegments.module.css'

export const TableOfSegments = ({
  segments,
  setSegmentToDelete,
  setIsDisplayingSegmentManagementModal,
}) => {
  const isMobile = useMobile()

  return (
    <table className={Style['table-of-segments']}>
      <thead>
        <tr>
          {!isMobile && <th>Nr</th>}
          <th>Segment Name</th>
          <th>Date Created</th>
          <th>dApp</th>
          <th>No. of users</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {segments.map((item: TSingleSegmentDetails, index: string) => (
          <tr key={`${item.name}${index}`}>
            {!isMobile && (
              <td>
                <Typography
                  tag="p"
                  text={index + 1}
                  size="s"
                  color="gray800"
                  weight="regular"
                />
              </td>
            )}
            <td>
              <Typography
                tag="p"
                text={item.name}
                size="s"
                color="gray800"
                weight="regular"
              />
            </td>
            <td>
              <div className={Style['dapp-column-container']}>
                <Typography
                  tag="p"
                  text={item.createdAt}
                  size="s"
                  color="gray800"
                  weight="regular"
                  className="pr32"
                />
              </div>
            </td>
            <td className={Style['dapp-cell']}>
              {item?.dapp ? (
                <div className={Style['dapp-label-container']}>
                  <img src={item.dapp.logo} alt={item.dapp.name} height="20" />
                  <Typography
                    text={item.dapp.name}
                    color="gray800"
                    tag="span"
                    size="s"
                  />
                </div>
              ) : null}
            </td>
            <td>
              <Typography
                tag="p"
                text={item?.walletCount || 'n/a'}
                size="s"
                color="gray800"
                weight="regular"
              />
            </td>
            <td>
              <div className={Style['settings-container']}>
                <IconButton
                  icon={<Icon name="bin" height="16" width="16" />}
                  onClick={() => {
                    setSegmentToDelete(item)
                    setIsDisplayingSegmentManagementModal(false)
                  }}
                />
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
