import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { ResultSchema } from 'types/result'
import { fetchResultData } from 'utils/fetches/resultData'
import {
  CustomBarChart,
  CustomPieChart,
  CustomAreaChart,
  CustomSankeyChart,
  SingleValue,
  Loader,
} from 'components/lib'
import { logger } from 'utils/logger'
import {
  adjustForSingleValue,
  removeUselessProperties,
  prepareSankeyData,
  calcHeight,
} from 'utils/helpers'
import { sankeyOption } from 'utils/constans'

import { fetchUserFlow } from 'features/builders/flowBuilder/utils/fetches'
import { prepareFiltersBodyRequestFormat } from 'features/dashboard/utils/helpers'

import Style from './Visualization.module.css'

export const Visualization = ({
  element,
  dashboardId,
  filters,
  dashboardTheme,
  elementHeight,
  dappId,
  dapp,
}) => {
  const [data, setData] = useState()
  const [isDataLoading, setIsDataLoading] = useState(false)
  const filtersBodyRequest = filters
    ? prepareFiltersBodyRequestFormat(filters)
    : []

  useEffect(() => {
    if (!element.data) {
      const fetchData = async () => {
        try {
          const elFiltersForRequest = removeUselessProperties(element.filters)
          setIsDataLoading(true)

          if (element.type === 'userFlow') {
            const fetchedResultData = await fetchUserFlow(element.segmentId)
            setData(fetchedResultData.data)
          } else {
            const bodyRequest = {
              breakdown: element.breakdown,
              filters: elFiltersForRequest,
            }
            if (element?.segmentId) {
              bodyRequest.segmentId = element.segmentId
            }
            const fetchedResultData = await fetchResultData(
              dappId,
              element.metric,
              bodyRequest
            )
            const validatedResultData = ResultSchema.safeParse(
              fetchedResultData.output
            )
            if (!validatedResultData.success) {
              logger.error(validatedResultData.error)
              throw Error('Unable to get data - incorrect response format.')
            }
            setData(validatedResultData.data)
          }

          setIsDataLoading(false)
        } catch (err) {
          setIsDataLoading(false)
          toast.error('Upss.. There was a problem to load data')
        }
      }

      fetchData()
    } else {
      setData(element.data)
    }
  }, [element?.type, JSON.stringify(filtersBodyRequest), dashboardId])

  if (element?.visType === 'singleValue') {
    return (
      <SingleValue
        data={adjustForSingleValue(data, 'N/A', element.metric)}
        title={element?.title}
        loading={isDataLoading}
        theme={dashboardTheme}
      />
    )
  }

  if (element?.type === 'userFlow') {
    return (
      <div
        className={Style['grid-item']}
        style={{
          borderRadius: dashboardTheme.itemGridRadius,
          backgroundColor: dashboardTheme.itemGridBgColor,
          color: dashboardTheme.textColor,
          borderColor: dashboardTheme.strokeColor,
        }}
      >
        <h3
          className={Style['item-title']}
          style={{
            color: dashboardTheme.textColor,
            fontFamily: dashboardTheme.font,
          }}
        >
          {element?.title}
        </h3>
        {isDataLoading && <Loader />}
        {!isDataLoading && data && (
          <CustomSankeyChart
            data={prepareSankeyData(data, dapp)}
            restOption={sankeyOption}
            style={{ height: calcHeight(elementHeight) }}
          />
        )}
      </div>
    )
  }

  return (
    <div
      className={Style['grid-item']}
      style={{
        borderRadius: dashboardTheme.itemGridRadius,
        backgroundColor: dashboardTheme.itemGridBgColor,
        color: dashboardTheme.textColor,
        borderColor: dashboardTheme.strokeColor,
      }}
    >
      <h3
        className={Style['item-title']}
        style={{
          color: dashboardTheme.textColor,
          fontFamily: dashboardTheme.font,
        }}
      >
        {element?.title}
      </h3>
      {isDataLoading && <Loader />}
      {!isDataLoading &&
        data &&
        {
          areaChart: (
            <CustomAreaChart
              data={data}
              height={calcHeight(elementHeight)}
              theme={dashboardTheme}
            />
          ),
          lineChart: (
            <CustomAreaChart
              data={data}
              height={calcHeight(elementHeight)}
              theme={dashboardTheme}
            />
          ),
          barChart: (
            <CustomBarChart
              data={data}
              height={calcHeight(elementHeight)}
              theme={dashboardTheme}
            />
          ),
          pieChart: (
            <CustomPieChart
              data={data}
              height={calcHeight(elementHeight)}
              theme={dashboardTheme}
            />
          ),
        }[element?.visType]}
    </div>
  )
}
