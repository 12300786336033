import React from 'react'

import { ButtonNew, Typography, Icon } from 'components/lib'

import Style from './SegmentsTopBar.module.css'

export const SegmentsTopBar = ({
  setIsDisplayingAddSegmentModal,
  setIsDisplayingSegmentManagementModal,
}) => {
  return (
    <div className={Style['top-bar']}>
      <Typography
        tag="h1"
        text="Segment Management"
        size="m"
        weight="medium"
        color="gray900"
      />
      <div className={Style['buttons-container']}>
        <ButtonNew
          color="primary500"
          variant="outline"
          onClick={() => setIsDisplayingSegmentManagementModal(false)}
        >
          Close
        </ButtonNew>
        <ButtonNew
          onClick={() => {
            setIsDisplayingAddSegmentModal(true)
            setIsDisplayingSegmentManagementModal(false)
          }}
          startIcon={<Icon name="plus" width="16" height="16" color="white" />}
        >
          Create New Segment
        </ButtonNew>
      </div>
    </div>
  )
}
