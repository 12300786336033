import React from 'react'

import { Icon } from 'components/icon/Icon'
import { determineChartDataFormat } from 'utils/helpers'

import Style from './ResultingFlowTable.module.css'

const TableHeaderCell = ({ label }) => (
  <th className={Style['head-cell']}>
    <div className={Style['head-cell-content']}>
      <p className={Style['head-cell-title']}>{label}</p>
    </div>
  </th>
)

export const ResultingFlowTable = ({ selectedSegment, data, dappName }) => {
  const columns = ['Chain Acquisition', 'App Acquisition']
  const formatter = Intl.NumberFormat('en', { notation: 'compact' })
  const uniqueNames = new Set()

  const links = data.map((item) => {
    if (!item.firstDapp?.name) {
    }
    return {
      source: item.firstDapp?.name,
      sourceIcon: item.firstDapp?.icon,
      target: `${item.lastDapp?.name}'`,
      targetIcon: `${item.lastDapp?.icon}`,
      value: item.cnt,
    }
  })

  const groupedBySource = links.reduce((acc, item) => {
    acc[item.source] = acc[item.source] || []
    acc[item.source].push(item)
    return acc
  }, {})

  const sumsOfSource = Object.entries(groupedBySource).map(([key, items]) => {
    const totalValue = items.reduce((sum, item) => sum + item.value, 0)
    const icon = items[0]?.sourceIcon || null // Pobranie ikony z pierwszego obiektu w grupie

    return {
      name: key,
      totalValue,
      icon,
    }
  })

  const groupedByTarget = links.reduce((acc, item) => {
    acc[item.target] = acc[item.target] || []
    acc[item.target].push(item)
    return acc
  }, {})

  const sumsOfTargets = Object.entries(groupedByTarget).map(([key, items]) => {
    const totalValue = items.reduce((sum, item) => sum + item.value, 0)
    return { name: key, totalValue }
  })

  sumsOfSource.sort((a, b) => b.totalValue - a.totalValue)
  sumsOfTargets.sort((a, b) => b.totalValue - a.totalValue)

  const addLastTrack = links.map((item) => {
    return {
      source: item.target,
      target: dappName,
      value: item.value,
    }
  })

  return (
    <div className={Style['resulting-datatable-container']}>
      {data && (
        <table className={Style['table']}>
          <thead>
            <tr className={Style['head-row']}>
              <th className={Style['head-cell']}>
                <div className={Style['head-cell-content']}>
                  <Icon name="c" width="16" height="16" />
                  <p className={Style['head-cell-title']}>Segment</p>
                </div>
              </th>
              {columns.map((column, index) => (
                <TableHeaderCell key={`${column}${index}`} label={column} />
              ))}
            </tr>
          </thead>
          <tbody>
            <tr className={Style['body-row']}>
              <td style={{ verticalAlign: 'top', textAlign: 'left' }}>
                {selectedSegment.name}
              </td>
              <td style={{ verticalAlign: 'top' }}>
                {sumsOfSource.map((item, index) => (
                  <p key={`${index}`}>
                    {item.name} &nbsp;
                    {item.totalValue}
                  </p>
                ))}
              </td>
              <td style={{ verticalAlign: 'top' }}>
                {sumsOfTargets.map((item, index) => (
                  <p key={`${index}`}>
                    {item.name} &nbsp;
                    {item.totalValue}
                  </p>
                ))}
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  )
}
