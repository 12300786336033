import React from 'react'

import { ButtonNew, Typography, Icon } from 'components/lib'

import Style from './NoSegmentContent.module.css'

type TNoSegmentContentProps = {
  setIsDisplayingAddSegmentModal: (value: boolean) => void
  setIsDisplayingSegmentManagementModal: (value: boolean) => void
}

export const NoSegmentContent = ({
  setIsDisplayingAddSegmentModal,
  setIsDisplayingSegmentManagementModal,
}: TNoSegmentContentProps) => (
  <div className={Style['nosegment-container']}>
    <Typography
      tag="p"
      text="There is no segments added at the moment"
      color="gray600"
      size="m"
      weight="regular"
    />
    <ButtonNew
      onClick={() => {
        setIsDisplayingSegmentManagementModal(false)
        setIsDisplayingAddSegmentModal(true)
      }}
      startIcon={<Icon name="plus" width="16" height="16" color="white" />}
    >
      Add Segment
    </ButtonNew>
  </div>
)
