/***
 *
 *   useSegments hook
 *   fetch, format and return segments
 *
 **********/

import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import { SegmentsDetailsSchema, TSegmentsDetails } from 'types/segment'
import { fetchSegments } from 'features/builders/segments/utils/fetches'
import { logger } from 'utils/logger'
import { getValidationErrorMessage } from 'utils/helpers'

export const useSegments = () => {
  const [segments, setSegments] = useState<TSegmentsDetails | undefined>()
  const [isLoadingSegments, setIsLoadingSegmnets] = useState<boolean>(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoadingSegmnets(true)
        const fetchedSegments = await fetchSegments()
        const validatedSegments =
          SegmentsDetailsSchema.safeParse(fetchedSegments)
        if (!validatedSegments.success) {
          logger.error(validatedSegments.error)
          throw Error(getValidationErrorMessage('Segments'))
        }
        setSegments(fetchedSegments)
        setIsLoadingSegmnets(false)
      } catch (err) {
        setIsLoadingSegmnets(false)
        logger.error(err)
        toast.error(getValidationErrorMessage('getting segments'))
      }
    }

    fetchData()
  }, [])

  return {
    segments,
    isLoadingSegments,
  }
}
