import { z } from 'zod'
// import { SingleBasicDappSchema } from 'types/dapp' // TODO uncomment, when response will be complete
import { CreatorSchema } from 'types/creator'

export const SegmentToAddSchema = z.object({
  name: z.string(),
  blockchain: z.string(),
  dapp: z.string(),
})

export const SingleSegmentSchema = z.object({
  name: z.string(),
  blockchain: z.string(),
  dapp: z.string(),
})

export const SingleSegmentDetailsSchema = z.object({
  name: z.string(),
  createdAt: z.string(),
  createdBy: z.string(),
  creator: CreatorSchema,
  // dapp: SingleBasicDappSchema, //  TODO uncomment, when response will be complete
  dapp: z.any(), // temporary solution waiting for full response
  dappId: z.string(),
  id: z.string(),
  // users: z.array(z.any()), // TODO resolve any, when response will be complete
  otherDapps: z.array(z.any()), // TODO resolve any, when response will be complete
  otherDappsUsed: z.array(z.any()), // TODO resolve any, when response will be complete
  usedFunctions: z.array(z.any()), // TODO resolve any, when response will be complete
})

export const SegmentsDetailsSchema = z.array(SingleSegmentDetailsSchema)

export type TSegment = z.infer<typeof SingleSegmentSchema>
export type TSegmentToAdd = z.infer<typeof SegmentToAddSchema>
export type TSegmentsDetails = z.infer<typeof SegmentsDetailsSchema>
export type TSingleSegmentDetails = z.infer<typeof SingleSegmentDetailsSchema>
