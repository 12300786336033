import React from 'react'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import * as echarts from 'echarts/core'
import { SankeyChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'
import tokenguard from './tokenguard'

echarts.use([SankeyChart, CanvasRenderer])

export const CustomSankeyChart = ({
  data,
  restOption,
  style,
  // theme
}) => {
  const option = {
    ...restOption,
    series: {
      ...restOption.series,
      data: data.data,
      links: data.links,
    },
  }

  return (
    <ReactEChartsCore
      echarts={echarts}
      option={option}
      notMerge={true}
      lazyUpdate={true}
      style={style}
      // theme={theme ? theme : tokenguard}
    />
  )
}
