import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import {
  Typography,
  Line,
  DropdownNew,
  IconButton,
  Loader,
  Icon,
} from 'components/lib'
import { logger } from 'utils/logger'
import { getValidationErrorMessage } from 'utils/helpers'

import {
  fetchCalls,
  fetchEvents,
  fetchSegments,
} from 'features/builders/shared/utils/fetches'
import { fetchUnits } from 'features/builders/chartBuilder/utils/fetches'
import { getUniqueObjsByProperty } from 'features/builders/shared/utils/helpers'
import { sortObjectsInArrByName } from 'features/builders/shared/utils/helpers'
import { MetricCard } from 'features/builders/shared/components/MetricCard'
import { MetricBar } from 'features/builders/shared/components/MetricBar'
import { TCallsResponse } from 'features/builders/chartBuilder/types/call'
import { EventCallDropdown } from 'features/builders/chartBuilder/components/EventCallDropdown'
import { EmptyMetricCard } from 'features/builders/shared/components/EmptyMetricCard'
import { SegmentDropdownAdditionalContent } from 'features/builders/shared/components/SegmentDropdownAdditionalContent'
import Style from './ChartBuilderSidebar.module.css'

export const ChartBuilderSidebar = ({
  setBlockChartId,
  setSelectedUnit,
  setSelectedEvents,
  setSelectedCalls,
  setSelectedSegment,
  selectedUnit,
  selectedEvents,
  selectedCalls,
  selectedSegment,
  isScBreakdown,
  setIsScBreakdown,
  dappId,
  isDisplayingAddSegmentModal,
  segmentToDelete,
}) => {
  const [callsOptions, setCallsOptions] = useState()
  const [eventsOptions, setEventsOptions] = useState()
  const [unitsOptions, setUnitsOptions] = useState()
  const [segmentsOptions, setSegmentsOptions] = useState()
  const [isLoadingSidebarData, setIsLoadingSidebarData] = useState<
    boolean | undefined
  >()

  const handleSelectedCalls = (item) => {
    setSelectedCalls((prevSelectedCalls) => {
      const hasCall = prevSelectedCalls?.some((obj) => obj.name === item.name)
      if (hasCall) {
        return prevSelectedCalls.filter((obj) => obj.name !== item.name)
      } else {
        if (prevSelectedCalls) {
          return [
            ...prevSelectedCalls,
            {
              name: item.name,
              type: 'call',
              filterOptions: item.args,
            },
          ]
        }
        return [
          {
            name: item.name,
            type: 'call',
            filterOptions: item.args,
          },
        ]
      }
    })
  }

  useEffect(() => {
    if (dappId) {
      const fetchData = async () => {
        try {
          setIsLoadingSidebarData(true)
          const fetchedUnits = await fetchUnits()
          const fetchedEvents = await fetchEvents(dappId)
          const fetchedCalls = await fetchCalls(dappId)
          const fetchedSegments = await fetchSegments(dappId)

          const uniqueObjsBySelectors = getUniqueObjsByProperty(
            fetchedCalls.contracts,
            'calls',
            'selectors'
          )
          const sortedCallsObjsByName = sortObjectsInArrByName(
            uniqueObjsBySelectors
          )
          const preparedCallOptions = sortedCallsObjsByName.map((item) => {
            return {
              type: selectedCalls?.some((obj) => obj.name === item.name)
                ? 'selected'
                : undefined,
              label: item.name,
              value: item.name,
              action: () => handleSelectedCalls(item),
            }
          })
          const uniqueObjsByName = getUniqueObjsByProperty(
            fetchedEvents.contracts,
            'events',
            'name'
          )
          const sortedOptionsObjsByName =
            sortObjectsInArrByName(uniqueObjsByName)
          const preparedEventOptions = sortedOptionsObjsByName.map((item) => {
            return {
              type: selectedEvents?.some((obj) => obj.name === item.name)
                ? 'selected'
                : undefined,
              label: item.name,
              value: item.name,
              action: () => handleEvent(item),
            }
          })
          const preparedUnitsOptions = fetchedUnits.output.units.map((item) => {
            return {
              type: item.value === selectedUnit?.value ? 'selected' : undefined,
              label: item.label,
              value: item.value,
              action: () =>
                setSelectedUnit({
                  name: item.label,
                  value: item.value,
                  icon: item.value,
                }),
              icon: item.value,
            }
          })

          const verifySelectedSegmentOption = (item, selectedSegment) => {
            if (item.id === selectedSegment?.id) {
              return 'selected'
            } else {
              return undefined
            }
          }

          const preparedSegmentsOptions = fetchedSegments.map((item) => {
            return {
              type: verifySelectedSegmentOption(item, selectedSegment),
              label: item.name,
              value: item.name,
              id: item.id,
              action: () => setSelectedSegment(item),
            }
          })

          setEventsOptions(preparedEventOptions)
          setCallsOptions(preparedCallOptions)
          setUnitsOptions(preparedUnitsOptions)
          setSegmentsOptions(preparedSegmentsOptions)
          setIsLoadingSidebarData(false)
        } catch (err) {
          setIsLoadingSidebarData(false)
          logger.error(err)
          toast.error(getValidationErrorMessage('Units, Events and Calls'))
        }
      }
      if (!isDisplayingAddSegmentModal && !segmentToDelete) {
        fetchData()
      }
    }
  }, [
    dappId,
    selectedUnit,
    selectedEvents,
    selectedCalls,
    selectedSegment,
    isDisplayingAddSegmentModal,
    segmentToDelete,
  ])

  const handleEvent = (item) => {
    setSelectedEvents((prevSelectedEvents) => {
      const hasEvent = prevSelectedEvents?.some((obj) => obj.name === item.name)
      if (hasEvent) {
        return prevSelectedEvents.filter((obj) => obj.name !== item.name)
      } else {
        if (prevSelectedEvents) {
          return [
            ...prevSelectedEvents,
            {
              name: item.name,
              type: 'event',
              filterOptions: item.args,
            },
          ]
        }
        return [
          {
            name: item.name,
            type: 'event',
            filterOptions: item.args,
          },
        ]
      }
    })
  }

  return (
    <div className={Style['list']}>
      <div className={Style['info-container']}>
        <div className={Style['title-bar']}>
          <IconButton
            onClick={() => setBlockChartId()}
            icon={<Icon name="back" width="16" height="16" color="gray800" />}
          />
          <Typography
            text="Set up"
            tag="h3"
            size="m"
            weight="semi-bold"
            color="gray900"
          />
        </div>
        <Typography
          text="Choose units, events/calls and which smart contracts
          you want to analyze"
          tag="p"
          size="s"
          weight="regular"
          color="gray700"
        />
      </div>
      <Line className="mt16 mb4" />
      {isLoadingSidebarData &&
        !unitsOptions &&
        !callsOptions &&
        !eventsOptions && (
          <div className="mt24 relative">
            <Loader />
          </div>
        )}
      {unitsOptions?.length > 0 ? (
        <MetricBar
          metricTitle="Units"
          dropdownComponent={
            <DropdownNew
              title="Units"
              size="medium"
              options={unitsOptions}
              position="bottom-right"
            >
              <IconButton
                icon={
                  <Icon name="plus" width="16" height="16" color="gray800" />
                }
              />
            </DropdownNew>
          }
        />
      ) : null}
      {selectedUnit && (
        <MetricCard
          metricType="Unit"
          selectedUnit={selectedUnit}
          setSelectedUnit={setSelectedUnit}
          isScBreakdown={isScBreakdown}
          setIsScBreakdown={setIsScBreakdown}
        />
      )}
      {!selectedUnit && unitsOptions?.length > 0 && (
        <EmptyMetricCard metricType="Unit" metricIcon="a" />
      )}
      {callsOptions && callsOptions.length > 0 ? (
        <MetricBar
          metricTitle="Events & Calls"
          dropdownComponent={
            <EventCallDropdown
              eventsOptions={eventsOptions}
              callsOptions={callsOptions}
              // id={item.column_name}
              position="bottom-right"
            >
              <IconButton
                icon={
                  <Icon name="plus" width="16" height="16" color="gray800" />
                }
              />
            </EventCallDropdown>
          }
        />
      ) : null}
      {selectedEvents && selectedEvents.length > 0 ? (
        <ul className={Style['list-of-metrics']}>
          {selectedEvents.map((item: any) => (
            <li key={item.name}>
              <MetricCard
                metricType="Event"
                metricItem={item}
                setSelectedEvents={setSelectedEvents}
                selectedEvents={selectedEvents}
              />
            </li>
          ))}
        </ul>
      ) : null}
      {selectedCalls && selectedCalls.length > 0 ? (
        <ul className={Style['list-of-metrics']}>
          {selectedCalls.map((item: any) => (
            <li key={item.name}>
              <MetricCard
                metricType="Call"
                metricItem={item}
                setSelectedCalls={setSelectedCalls}
                selectedCalls={selectedCalls}
              />
            </li>
          ))}
        </ul>
      ) : null}
      {(!selectedCalls || selectedCalls.length === 0) &&
        (!selectedEvents || selectedEvents.length === 0) &&
        (callsOptions?.length || eventsOptions?.length) > 0 && (
          <EmptyMetricCard metricType="Event / Call" metricIcon="b" />
        )}
      {segmentsOptions?.length >= 0 && !isLoadingSidebarData ? (
        <MetricBar
          metricTitle="Segment"
          dropdownComponent={
            <DropdownNew
              title="Segment"
              size="medium"
              options={segmentsOptions}
              position="bottom-right"
              content={<SegmentDropdownAdditionalContent />}
            >
              <IconButton
                icon={
                  <Icon name="plus" width="16" height="16" color="gray800" />
                }
              />
            </DropdownNew>
          }
        />
      ) : null}
      {selectedSegment &&
        !isLoadingSidebarData &&
        segmentsOptions?.length > 0 && (
          <MetricCard
            metricType="Segment"
            selectedSegment={
              selectedSegment?.name
                ? selectedSegment
                : {
                    name: segmentsOptions.filter(
                      (segment) => segment.id === selectedSegment.id
                    )[0]?.value,
                  }
            }
            setSelectedSegment={setSelectedSegment}
          />
        )}
      {segmentsOptions?.length >= 0 &&
        !isLoadingSidebarData &&
        !selectedSegment && (
          <EmptyMetricCard metricType="Segment" metricIcon="c" />
        )}
    </div>
  )
}
