/***
 *
 *   DAPP SANKEY SECTION
 *
 **********/

import React from 'react'
import ReactDOMServer from 'react-dom/server'

import user from 'assets/icons/user-green.svg'
import {
  CustomSankeyChart,
  Typography,
  Icon,
  CustomTooltip,
  Image,
  useMobile,
} from 'components/lib'
import informations from 'utils/informations.json'

import { useSankeyData } from 'features/gi/dapp/giDappBasicDashboard/hooks/useSankeyData'
import { convertToSankeyFormat } from 'features/gi/dapp/giDappBasicDashboard/utils/helpers'
import Style from './dappSankeySection.module.scss'

export const DappSankeySection = ({ dappSlug, blockchains }) => {
  const isMobile = useMobile()
  const { sankeyData, _isLoadingSankeyData } = useSankeyData(
    dappSlug,
    blockchains
  )
  const suffix = ' '

  if (!sankeyData) {
    return null
  }

  const sankeyOption = {
    tooltip: {
      trigger: 'item',
      triggerOn: 'mousemove',
    },
    series: {
      type: 'sankey',
      height: 300,
      nodeAlign: 'justify',
      nodeWidth: 20,
      left: 40,
      right: 40,
      nodeGap: 40,
      orient: 'horizontal',
      draggable: false,
      emphasis: {
        focus: 'adjacency',
      },
    },
  }

  const sankeyStyle = {
    width: '100%',
    height: 350,
  }

  return (
    <div className={Style.sankeySection}>
      <div className={Style.barContainer}>
        <Image source={user} />
        <Typography
          tag="h2"
          color="gray900"
          size="m"
          weight="semi-bold"
          text="Movement Of Users"
          className="nowrap"
        />
        <div
          className="relative"
          data-tooltip-id="movement-of-users"
          data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
            <Typography
              text={informations['Movement of Users']}
              tag="p"
              weight="regular"
              size="s"
              color="gray700"
            />
          )}
          data-tooltip-place="bottom-start"
          data-tooltip-position-strategy="fixed"
          data-tooltip-offset={8}
        >
          <Icon name="information2" width="16" height="16" />
          <CustomTooltip id="movement-of-users" light />
        </div>
      </div>
      {sankeyData?.length > 0 ? (
        <CustomSankeyChart
          data={convertToSankeyFormat(sankeyData, suffix, isMobile)}
          restOption={sankeyOption}
          style={sankeyStyle}
        />
      ) : null}
      {sankeyData?.length === 0 ? (
        <div className="relative min-height">
          <p className="align-xy">There is currently no data available.</p>
        </div>
      ) : null}
    </div>
  )
}
