import React, { useContext } from 'react'

import { ButtonNew } from 'components/lib'

import { SegmentManagementModalContext } from 'features/builders/shared/contexts/SegmentManagementModalContext'
import Style from './SegmentDropdownAdditionalContent.module.css'

export const SegmentDropdownAdditionalContent = () => {
  const { setIsDisplayingSegmentManagementModal } = useContext(
    SegmentManagementModalContext
  )

  return (
    <div className={Style['segment-dropdown-additional-content']}>
      <ButtonNew
        onClick={() => setIsDisplayingSegmentManagementModal(true)}
        variant="outline"
        color="green500"
        fullWidth
      >
        Segment Management
      </ButtonNew>
    </div>
  )
}
