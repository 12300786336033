/***
 *
 *   LOADER
 *
 **********/

import React from 'react'
import clsx from 'clsx'

import { Typography } from 'components/lib'

import ThreeDots from './images/three-dots.svg'
import Style from './Loader.module.css'

type TLoaderProps = {
  className?: string
  text?: string
}

export const Loader = ({ className, text }: TLoaderProps) => {
  const loaderStyle = clsx(Style['loader'], className)

  return (
    <>
      <div className={loaderStyle}>
        {text && <Typography tag="p" text={text} size="s" />}
        <img className={Style['spinner']} src={ThreeDots} alt="dots loader" />
      </div>
    </>
  )
}
