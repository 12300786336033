import React from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import settings from 'settings'

import { ButtonNew, ModalNew, Typography, useNavigate } from 'components/lib'

import Style from './DeleteSegment.module.css'

const serverURL = settings[process.env.REACT_APP_DEPLOY_ENV].server_url

export const DeleteSegment = ({
  segment,
  setSegmentToDelete,
  // setIsDisplayingDeleteSegmentModal,
}) => {
  const navigate = useNavigate()

  const deleteSegment = async () => {
    try {
      await axios.delete(`${serverURL}/api/segment/${segment.id}`)
      toast.success('Segment removed successfully')
      setSegmentToDelete(undefined)
      // setIsDisplayingDeleteSegmentModal(false)
    } catch (err) {
      toast.error(
        `We're sorry, the segment could not be removed. Please try again later.`
      )
    }
  }

  return (
    <>
      {segment && (
        <ModalNew
          title={`Delete ${segment.name} segment?`}
          hasCloseButton={false}
          isOpen={() => {
            setSegmentToDelete(undefined)
            // setIsDisplayingDeleteSegmentModal(false)
          }}
        >
          <div className={Style['container']}>
            <Typography
              tag="p"
              text="Are you sure you want to delete this segment? You will loose data in all charts with it set"
              color="gray500"
              size="m"
              weight="regular"
            />
            <div className={Style['buttons-container']}>
              <ButtonNew
                variant="outline"
                color="green500"
                onClick={() => {
                  setSegmentToDelete(undefined)
                  // setIsDisplayingDeleteSegmentModal(false)
                }}
              >
                No, Cancel
              </ButtonNew>
              <ButtonNew color="red500" onClick={() => deleteSegment()}>
                Yes, Delete
              </ButtonNew>
            </div>
          </div>
        </ModalNew>
      )}
    </>
  )
}
