import React from 'react'
import { Label, Error, IconLib, ClassHelper, Icon } from 'components/lib'
import Style from './input.module.scss'

export function TextInput(props) {
  const error = props.errorMessage || 'Please enter a value'

  function validate(e) {
    let value = e ? e.target.value : ''
    let valid = undefined

    // input is required and value is blank
    if (props.required && value === '') valid = false

    // input isn't required and value is blank
    if (!props.required && value === '') valid = true

    if (props.required && value !== '') valid = true

    // update the parent form
    props.onChange?.(props.name, value, valid)
  }

  // style
  const textStyle = ClassHelper(Style, {
    textbox: true,
    className: props.className,
    error: props.valid === false,
    success: props.valid === true,
    warning: props.warning,
    iconSpace: props.icon,
  })

  return (
    <div className={Style.input}>
      {props.label && (
        <Label
          text={props.label}
          required={props.required}
          forInput={props.name}
          capitalize={props.small}
        />
      )}
      {props.type === 'textarea' ? (
        <textarea
          id={props.id}
          name={props.name}
          value={props.value}
          className={textStyle}
          placeholder={props.placeholder}
          onChange={(e) =>
            props.onChange?.(props.name, e.target.value, undefined)
          }
          onBlur={(e) => validate(e)}
        ></textarea>
      ) : (
        <div className={Style['input-container']}>
          <input
            type="text"
            id={props.id}
            name={props.name}
            value={props.value || ''}
            className={textStyle}
            placeholder={props.placeholder}
            onChange={(e) =>
              props.onChange?.(props.name, e.target.value, undefined)
            }
            onBlur={(e) => validate(e)}
          />

          {props.valid === true && (
            <Icon name="check" color="green500" width="16" height="16" />
          )}
        </div>
      )}
      {props.valid === false && <Error message={error} />}
      {props.icon && (
        <IconLib pack="own" image={props.icon} className={Style.icon} />
      )}
    </div>
  )
}
