import React, { useState, useContext } from 'react'

import { DashboardContentContext } from 'app/contexts/DashboardContentContext'
import { useOutsideClick } from 'hooks/useOutsideClick'
import { TextInputNew, IconButton, ButtonNew } from 'components/lib'
import { Icon } from 'components/icon/Icon'
import { removeUselessProperties } from 'utils/helpers'

// import { BlockChartContext } from 'features/builders/shared/contexts/BlockChartContext'
import { BlockFlowContext } from 'features/builders/shared/contexts/BlockFlowContext'
import Style from './SettingsChart.module.css'

export const SettingsChart = ({
  chartType,
  selectedSegment,
  chartTitle,
  setChartTitle,
  dappId,
  result,
}) => {
  const [editing, setEditing] = useState<boolean>(false)
  const blockFlowContext = useContext(BlockFlowContext)
  const dashboardContentContext = useContext(DashboardContentContext)

  if (!blockFlowContext) {
    throw Error('Flow context has to be in provider')
  }

  if (!dashboardContentContext) {
    throw Error('Dashboard context has to be in provider')
  }

  const { blockFlowId, setBlockFlowId } = blockFlowContext
  const {
    dashboardElements,
    setDashboardElements,
    dashboardLayout,
    setDashboardLayout,
  } = dashboardContentContext

  const saveElement = (blockFlowId) => {
    if (dashboardElements.some((obj) => obj?.id === blockFlowId)) {
      const updatedDashboardElements = dashboardElements.map((element) => {
        if (element.id === blockFlowId) {
          // const selectedCallsWithoutFilterOptions =
          //   removeUselessProperties(selectedCalls)
          // const selectedEventsWithoutFilterOptions =
          //   removeUselessProperties(selectedEvents)
          const elementToSave = {
            id: element.id,
            title: chartTitle,
            visType: 'user-flow',
            // metric: selectedUnit.value,
            // breakdown: isScBreakdown,
            data: result,
            filters: [],
          }
          // selectedCallsWithoutFilterOptions &&
          //   elementToSave.filters.push(...selectedCallsWithoutFilterOptions)
          // selectedEventsWithoutFilterOptions &&
          //   elementToSave.filters.push(...selectedEventsWithoutFilterOptions)

          return {
            ...element,
            ...elementToSave,
          }
        } else {
          return element
        }
      })
      setDashboardElements(updatedDashboardElements)
      setBlockFlowId(null)
    } else {
      // const selectedCallsWithoutFilterOptions =
      //   removeUselessProperties(selectedCalls)
      // const selectedEventsWithoutFilterOptions =
      //   removeUselessProperties(selectedEvents)
      const elementToSave = {
        type: 'userFlow',
        visType: 'user-flow',
        id: blockFlowId,
        i: blockFlowId,
        title: chartTitle,
        dappId: dappId,
        segmentId: selectedSegment.id,
        // metric: selectedUnit.value,
        // breakdown: isScBreakdown,
        data: result,
        filters: [],
      }
      // selectedCallsWithoutFilterOptions &&
      //   elementToSave.filters.push(...selectedCallsWithoutFilterOptions)
      // selectedEventsWithoutFilterOptions &&
      //   elementToSave.filters.push(...selectedEventsWithoutFilterOptions)
      setDashboardElements([...dashboardElements, elementToSave])
      setDashboardLayout([
        ...dashboardLayout,
        {
          id: blockFlowId,
          i: blockFlowId,
          static: false,
          x: 0,
          y: 0,
          h: 24,
          w: 4,
        },
      ])
      setBlockFlowId(null)
    }
  }

  const ref = useOutsideClick(() => {
    setEditing(false)
  })

  const handleClick = () => {
    setEditing(true)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChartTitle(e.target.value)
  }

  const handleBlur = () => {
    setEditing(false)
  }

  return (
    <div className={Style['settings-chart-container']}>
      <div className={Style['chart-title-container']} ref={ref}>
        {editing ? (
          <TextInputNew
            name="chart-title"
            value={chartTitle}
            change={handleChange}
            blur={handleBlur}
          />
        ) : (
          <p className={Style['chart-title']}>{chartTitle}</p>
        )}
        <IconButton
          onClick={handleClick}
          icon={<Icon name="edit" height="1.6rem" width="1.6rem" />}
        />
      </div>
      <ButtonNew onClick={() => saveElement(blockFlowId)}>
        Save to Dashboard
      </ButtonNew>
    </div>
  )
}
