/***
 *
 *   DELETE SEGMENT MODAL CONTEXT
 *
 **********/

import React, { useState, createContext, ReactNode } from 'react'
import { TSegment } from 'types/segment'

export interface IDeleteSegmentModalContextProps {
  segmentToDelete: TSegment | undefined
  setSegmentToDelete: React.Dispatch<React.SetStateAction<TSegment | undefined>>
}

interface IDeleteSegmentModalProviderProps {
  children: ReactNode
}

export const DeleteSegmentModalContext = createContext<
  IDeleteSegmentModalContextProps | undefined
>(undefined)

export function DeleteSegmentModalProvider({
  children,
}: IDeleteSegmentModalProviderProps) {
  const [segmentToDelete, setSegmentToDelete] = useState()

  return (
    <DeleteSegmentModalContext.Provider
      value={{
        segmentToDelete,
        setSegmentToDelete,
      }}
    >
      {children}
    </DeleteSegmentModalContext.Provider>
  )
}
