export const sortObjectsInArrByName = <T extends { name: string }>(
  arr: Array<T>
): Array<T> => {
  arr.sort((a, b) => {
    if (a.name < b.name) {
      return -1
    }
    if (a.name > b.name) {
      return 1
    }
    return 0
  })

  return arr
}

export function getUniqueObjsByProperty(
  contracts: Array<unknown>,
  propertyName: string,
  uniqueKey: string
) {
  const uniqueMap = new Map()

  contracts.forEach((contract: any) => {
    if (!Array.isArray(contract[propertyName])) {
      throw new Error(`Property ${propertyName} must be an array.`)
    }

    contract[propertyName].forEach((item: any) => {
      if (!uniqueMap.has(item[uniqueKey])) {
        uniqueMap.set(item[uniqueKey], item)
      }
    })
  })

  return Array.from(uniqueMap.values())
}
