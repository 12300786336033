import React, { useContext } from 'react'

import { Loader, ModalNew } from 'components/lib'

import { TableOfSegments } from 'features/builders/segments/components/TableOfSegments'
import { SegmentsTopBar } from 'features/builders/segments/components/SegmentsTopBar'
import { useSegments } from 'features/builders/segments/hooks/useSegments'
import { NoSegmentContent } from 'features/builders/segments/components/NoSegmentContent'
import { AddSegmentModalContext } from 'features/builders/shared/contexts/AddSegmentModalContext'
import { DeleteSegmentModalContext } from 'features/builders/shared/contexts/DeleteSegmentModalContext'
import { SegmentManagementModalContext } from 'features/builders/shared/contexts/SegmentManagementModalContext'
import Style from './SegmentsManagement.module.css'

export const SegmentsManagement = () => {
  const addSegmentContext = useContext(AddSegmentModalContext)
  const deleteSegmentContext = useContext(DeleteSegmentModalContext)
  const segmentManagmentContext = useContext(SegmentManagementModalContext)

  if (!deleteSegmentContext) {
    throw Error('Delete Segment modal context has to be in provider')
  }

  if (!addSegmentContext) {
    throw Error('Add Segment modal context has to be in provider')
  }

  if (!segmentManagmentContext) {
    throw Error('Segment Management modal context has to be in provider')
  }

  const { setSegmentToDelete } = deleteSegmentContext
  const { setIsDisplayingAddSegmentModal } = addSegmentContext
  const { setIsDisplayingSegmentManagementModal } = segmentManagmentContext
  const { segments, isLoadingSegments } = useSegments()

  return (
    <>
      {!isLoadingSegments && segments?.length > 0 ? (
        <ModalNew
          hasCloseButton={false}
          isOpen={setIsDisplayingSegmentManagementModal}
        >
          <div className={Style['content-container']}>
            <SegmentsTopBar
              setIsDisplayingAddSegmentModal={setIsDisplayingAddSegmentModal}
              setIsDisplayingSegmentManagementModal={
                setIsDisplayingSegmentManagementModal
              }
            />
            {!isLoadingSegments && segments && segments?.length > 0 ? (
              <TableOfSegments
                setSegmentToDelete={setSegmentToDelete}
                segments={segments}
                setIsDisplayingSegmentManagementModal={
                  setIsDisplayingSegmentManagementModal
                }
              />
            ) : null}
            {!isLoadingSegments && segments && segments?.length === 0 ? (
              <NoSegmentContent
                setIsDisplayingAddSegmentModal={setIsDisplayingAddSegmentModal}
                setIsDisplayingSegmentManagementModal={
                  setIsDisplayingSegmentManagementModal
                }
              />
            ) : null}
            {isLoadingSegments && (
              <div className="relative min-height">
                <Loader />
              </div>
            )}
          </div>
        </ModalNew>
      ) : null}
    </>
  )
}
