/***
 *
 *   FLOW BUILDER
 *
 **********/

import React, { useContext, useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'

import { logger } from 'utils/logger'
import dappSymbol from 'assets/images/dapp-symbol.svg'
import { DashboardContentContext } from 'app/contexts/DashboardContentContext'
import { Typography, Loader, CustomSankeyChart } from 'components/lib'
import { TResult } from 'types/result'
import { getValidationErrorMessage, prepareSankeyData } from 'utils/helpers'
import { sankeyOption } from 'utils/constans'

import { fetchSegments } from 'features/builders/shared/utils/fetches'
import { SegmentsManagement } from 'features/builders/segments/SegmentsManagement'
import { FlowBuilderSidebar } from 'features/builders/flowBuilder/components/FlowBuilderSidebar'
import { ResultingFlowTable } from 'features/builders/flowBuilder/components/ResultingFlowTable'
import { fetchUserFlow } from 'features/builders/flowBuilder/utils/fetches'
import { SettingsChart } from 'features/builders/flowBuilder/components/SettingsChart'
import { BlockFlowContext } from 'features/builders/shared/contexts/BlockFlowContext'
import { Window } from 'features/builders/shared/components/Window'
import { AddSegment } from 'features/builders/shared/components/AddSegment'
import { DeleteSegment } from 'features/builders/shared/components/DeleteSegment'
import { SegmentManagementModalContext } from 'features/builders/shared/contexts/SegmentManagementModalContext'
import { AddSegmentModalContext } from 'features/builders/shared/contexts/AddSegmentModalContext'
import { DeleteSegmentModalContext } from 'features/builders/shared/contexts/DeleteSegmentModalContext'

import Style from './FlowBuilder.module.css'

type TSelectedSegment = {
  dappId: string
  name: string
  createdBy: string
  otherDappsUsed: Array<unknown>
  firstInteractionMin: Date | null
  firstInteractionMax: Date | null
  usedFunctions: Array<unknown>
  lastObservationMin: Date | null
  lastObservationMax: Date | null
  id: string
  createdAt: Date
  walletCount: number
  otherDapps: Array<unknown>
}

export function FlowBuilder() {
  const [flowTitle, setFlowTitle] = useState<string | undefined>('Acquisition')
  const [result, setResult] = useState<TResult | undefined>()
  const [editedFlowElement, setEditedFlowElement] = useState<undefined>()
  const [isLoadingResult, setIsLoadingResult] = useState<boolean | undefined>()
  const [selectedSegment, setSelectedSegment] = useState<
    TSelectedSegment | undefined
  >()
  const [segmentsOptions, setSegmentsOptions] = useState([])
  const [isLoadingSidebarData, setIsLoadingSidebarData] = useState<
    boolean | undefined
  >()
  const blockFlowContext = useContext(BlockFlowContext)
  const addSegmentContext = useContext(AddSegmentModalContext)
  const deleteSegmentContext = useContext(DeleteSegmentModalContext)
  const segmentManagmentContext = useContext(SegmentManagementModalContext)
  const dashboardContentContext = useContext(DashboardContentContext)
  const location = useLocation()
  const [, firstSegment] = location.pathname.split('/')
  const flowResultController = useRef<AbortController | null>(null)

  if (!deleteSegmentContext) {
    throw Error('Delete Segment modal context has to be in provider')
  }

  if (!addSegmentContext) {
    throw Error('Add Segment modal context has to be in provider')
  }

  if (!blockFlowContext) {
    throw Error('Chart context has to be in provider')
  }

  if (!dashboardContentContext) {
    throw Error('Dashboard context has to be in provider')
  }

  if (!segmentManagmentContext) {
    throw Error('Segment Managment context has to be in provider')
  }

  const { isDisplayingSegmentManagementModal } = segmentManagmentContext
  const { segmentToDelete, setSegmentToDelete } = deleteSegmentContext
  const { isDisplayingAddSegmentModal } = addSegmentContext
  const { blockFlowId, setBlockFlowId } = blockFlowContext
  const { dashboardElements, dashboardDappId } = dashboardContentContext

  useEffect(() => {
    const flowElement = dashboardElements.filter(
      (item) => item.id === blockFlowId
    )[0]
    if (flowElement) {
      setEditedFlowElement(flowElement)
      setFlowTitle(flowElement.title)
    }
  }, [blockFlowId])

  useEffect(() => {
    if (dashboardDappId && segmentsOptions.length === 0) {
      const fetchData = async () => {
        try {
          setIsLoadingSidebarData(true)
          const fetchedSegments = await fetchSegments(dashboardDappId)   
          setSegmentsOptions(fetchedSegments)
          setIsLoadingSidebarData(false)

          if (editedFlowElement) {
            setSelectedSegment(
              fetchedSegments.filter(
                (item) => item.id === editedFlowElement.segmentId
              )[0]
            )
          }
        } catch (err) {
          setIsLoadingSidebarData(false)
          logger.error(err)
          toast.error(getValidationErrorMessage('Segments'))
        }
      }

      fetchData()
    }

  }, [
    dashboardDappId,
    editedFlowElement,
  ])

  useEffect(() => {
    if (selectedSegment) {
      const fetchData = async () => {
        try {
          if (flowResultController.current) {
            flowResultController.current.abort()
          }

          flowResultController.current = new AbortController()
          const signal = flowResultController.current.signal
          setIsLoadingResult(true)
          const fetchedResultData = await fetchUserFlow(
            selectedSegment.id,
            signal
          )
          setResult(fetchedResultData.data)
          // const validatedResultData = ResultSchema.safeParse(
          //   fetchedResultData.output
          // )
          // if (!validatedResultData.success) {
          //   throw new Error(validatedResultData.error)
          // }
          setIsLoadingResult(false)
          // setResult(validatedResultData.data)
        } catch (err) {
          if (err.code !== 'ERR_CANCELED') {
            setIsLoadingResult(false)
            logger.error(err.message)
            toast.error(getValidationErrorMessage('result'))
          }
        }
      }

      fetchData()
    } else {
      setResult(undefined)
    }
  }, [selectedSegment])

  return (
    <>
      {isDisplayingAddSegmentModal && <AddSegment dappId={dashboardDappId} />}
      {isDisplayingSegmentManagementModal && <SegmentsManagement />}
      {segmentToDelete && (
        <DeleteSegment
          segment={segmentToDelete}
          setSegmentToDelete={setSegmentToDelete}
        />
      )}
      <Window>
        <div className={Style['builder-container']}>
          <FlowBuilderSidebar
            setBlockFlowId={setBlockFlowId}
            setSelectedSegment={setSelectedSegment}
            selectedSegment={selectedSegment}
            segmentsOptions={segmentsOptions}
          />
          <div className={Style['preview']}>
            {!selectedSegment && !isLoadingResult && (
              <div className={Style['prevew-content-container']}>
                <img
                  src={dappSymbol}
                  alt="dapp symbol"
                  className={Style['img-distance']}
                />
                <Typography
                  text="Select a segment to get started"
                  size="m"
                  weight="semi-bold"
                  color="gray900"
                  tag="p"
                />
              </div>
            )}
            {selectedSegment && result?.length > 0 && !isLoadingResult && (
              <>
                <SettingsChart
                  setChartTitle={setFlowTitle}
                  chartTitle={flowTitle}
                  chartType="userFlow"
                  dappId={dashboardDappId}
                  result={result}
                  selectedSegment={selectedSegment}
                />
                <CustomSankeyChart
                  data={prepareSankeyData(result, firstSegment)}
                  restOption={sankeyOption}
                  style={{ height: 800 }}
                />
                <ResultingFlowTable
                  selectedSegment={selectedSegment}
                  data={result}
                  dappName={firstSegment}
                />
              </>
            )}
            {selectedSegment && result?.length === 0 && (
              <Typography
                tag="p"
                size="s"
                text="The segment does not contain data."
                className="align-xy"
              />
            )}
            {isLoadingResult && <Loader />}
          </div>
        </div>
      </Window>
    </>
  )
}
