import React, { useContext } from 'react'

import { DashboardContentContext } from 'app/contexts/DashboardContentContext'
import { BlockButtonContext } from 'features/builders/shared/contexts/BlockButtonContext'
import { BlockTextContext } from 'features/builders/shared/contexts/BlockTextContext'
import { BlockChartContext } from 'features/builders/shared/contexts/BlockChartContext'
import { BlockFlowContext } from 'features/builders/shared/contexts/BlockFlowContext'
import { generateUniqueString } from 'utils/helpers'

import Style from './AddNewElement.module.css'
import visualization from './assets/icons/visualization.svg'
import userFlow from './assets/icons/user-flow.svg'
import text from './assets/icons/text.svg'
import button from './assets/icons/button.svg'

const elements = [
  { type: 'user-flow', name: 'User Flow', icon: userFlow },
  { type: 'visualization', name: 'Data Charts', icon: visualization },
  { type: 'text', name: 'Text', icon: text },
  { type: 'button', name: 'Button', icon: button },
]

interface AddNewElementProps {
  closeToolbar?: (arg: boolean) => void
  title: boolean
}

export const AddNewElement: React.FC<AddNewElementProps> = ({
  closeToolbar,
  title,
}) => {
  const { dashboardElements } = useContext(DashboardContentContext)

  const blockButtonContext = useContext(BlockButtonContext)
  const blockTextContext = useContext(BlockTextContext)
  const blockChartContext = useContext(BlockChartContext)
  const blockFlowContext = useContext(BlockFlowContext)

  if (!blockButtonContext) {
    throw new Error('Button controller context must be used in Provider')
  }

  if (!blockTextContext) {
    throw new Error('Block text context must be used in Provider')
  }

  if (!blockChartContext) {
    throw new Error('Block chart context must be used in Provider')
  }

  if (!blockFlowContext) {
    throw new Error('Block flow context must be used in Provider')
  }

  const { setBlockButtonId } = blockButtonContext
  const { setBlockTextId } = blockTextContext
  const { setBlockChartId } = blockChartContext
  const { setBlockFlowId } = blockFlowContext

  const addElement = (elementType: string) => {
    const elementId = generateUniqueString(dashboardElements)

    if (elementType === 'button') {
      setBlockButtonId(elementId)
      closeToolbar && closeToolbar(false)
    } else if (elementType === 'text') {
      setBlockTextId(elementId)
      closeToolbar && closeToolbar(false)
    } else if (elementType === 'visualization') {
      setBlockChartId(elementId)
      closeToolbar && closeToolbar(false)
    } else if (elementType === 'user-flow') {
      setBlockFlowId(elementId)
      closeToolbar && closeToolbar(false)
    }
  }

  return (
    <>
      <div className={Style['add-new-element']}>
        {title && (
          <p className={Style['text']}>Add an element to get started</p>
        )}
        <ul className={Style['elements-list']}>
          {elements.map((element) => (
            <li
              className={Style['element-list']}
              key={element.type}
              onClick={() => addElement(element.type)}
            >
              <img
                src={element.icon}
                alt=""
                className={Style['element-icon']}
              />
              <p className={Style['element-name']}>{element.name}</p>
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}
